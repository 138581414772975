import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import FormInput from '../../components/form/FormInput';

const RoleEdit = () => {
      const { roleId } = useParams();

      const [role, setRole] = useState([]);

      const [permissions, setPermissions] = useState([]);
      const [selectedPermissions, setSelectedPermissions] = useState([]);
      const [loadingData, setLoadingData] = useState(false);
      const [roleName, setRoleName] = useState('');

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles/${roleId}/edit`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPermissions(response.data.permissions);
                              setRole(response.data.role);
                              setRoleName(response.data.role.name);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (e) => {
            e.preventDefault();

            const roleData = {
                  name: roleName ? roleName : role.name,
                  permissions: selectedPermissions
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles/${roleId}/update`,
                        roleData
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handlePermissionChange = (permissionId) => (e) => {
            const updatedPermissions = permissions.map((permission) => ({
                  ...permission,
                  checked:
                        permission.id === permissionId
                              ? e.target.checked
                              : permission.checked
            }));
            setPermissions(updatedPermissions);
            const selectedPermissionIds = updatedPermissions
                  .filter((permission) => permission.checked)
                  .map((permission) => permission.id);
            setSelectedPermissions(selectedPermissionIds);
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Edit Role">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/roles"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row className="g-3">
                                                <FormInput
                                                      title="Role Name"
                                                      classes={12}
                                                      value={roleName}
                                                      handleChange={(e) =>
                                                            setRoleName(
                                                                  e.target.value
                                                            )
                                                      }
                                                />

                                                <div className="row mt-3">
                                                      {!loadingData &&
                                                            permissions.length >
                                                                  0 &&
                                                            permissions.map(
                                                                  (
                                                                        permission
                                                                  ) => (
                                                                        <Col
                                                                              key={
                                                                                    permission.id
                                                                              }
                                                                              lg={
                                                                                    3
                                                                              }
                                                                        >
                                                                              <Form.Check
                                                                                    type="switch"
                                                                                    id={`custom-switch-${permission.id}`}
                                                                                    label={
                                                                                          permission.name
                                                                                    }
                                                                                    checked={
                                                                                          permission.checked
                                                                                    }
                                                                                    onChange={handlePermissionChange(
                                                                                          permission.id
                                                                                    )} // Pass permission ID as argument
                                                                              />
                                                                        </Col>
                                                                  )
                                                            )}
                                                </div>
                                          </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                          <button type="submit">
                                                Save Role
                                          </button>
                                    </Card.Footer>
                              </Card>
                        </Col>
                  </Form>
            </>
      );
};

export default RoleEdit;
