import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';

import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';
import ProductImage from '../../components/common/ProductImage';
import FormStatus from '../../components/form/FormStatus';
import EditButton from '../../components/common/EditButton';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import CardBody from '../../components/common/CardBody';

const PageList = () => {
      const [pages, setPages] = useState([]);
      const [loading, setLoading] = useState([]);
      const [totalPages, setTotalPages] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setPages(response.data.pages);
                        setTotalPages(response.data.totalPages);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPages(response.data.pages);
                              setTotalPages(response.data.totalPages);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleStatus = async (page) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${page.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (page) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${page.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPages((pages) =>
                                    pages.filter((item) => item.id !== page.id)
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Page List">
                                          <LinkButton
                                                link="/dashboard/pages/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          perPages={perPages}
                                          currentPage={currentPage}
                                          totalItems={totalPages}
                                          setSearch={setSearch}
                                          getData={getData}
                                    >
                                          <thead>
                                                <tr>
                                                      <th className="text-center">
                                                            #
                                                      </th>
                                                      <th>Title</th>
                                                      <th>Slug</th>
                                                      <th width="140px">
                                                            Date
                                                      </th>
                                                      <th width="80px">
                                                            Status
                                                      </th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      pages?.length > 0 ? (
                                                            pages.map(
                                                                  (
                                                                        page,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      page.title
                                                                                                }
                                                                                                category={`By: ${page.user_name}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                page.slug
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                page.date
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <FormStatus
                                                                                                item={
                                                                                                      page
                                                                                                }
                                                                                                handleStatus={
                                                                                                      handleStatus
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <EditButton
                                                                                                link={`/dashboard/pages/${page.slug}/edit`}
                                                                                          />
                                                                                          <ViewButton
                                                                                                link={`/dashboard/pages/${page.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      page
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    6
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Pages
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              6
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        <Spinner />
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PageList;
