import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import {
      FormButton,
      FormImage,
      FormInput,
      FormStatusSelect
} from '../../components/form';

const CatalogueEdit = () => {
      const { catalogueId } = useParams();

      const [title, setTitle] = useState('');
      const [image, setImage] = useState('');
      const [imageUrl, setImageUrl] = useState('');
      const [fileUrl, setFileUrl] = useState('');
      const [file, setFile] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTitle(response.data.catalogue.title);
                              setStatus(response.data.catalogue.status);
                              setImageUrl(response.data.catalogue.image);
                              if (response.data.catalogue.file) {
                                    setFileUrl('/images/icons/pdf.png');
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  file: file,
                  status: status,
                  image: image
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.file) {
                                    toast.error(response.data.message.file);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Catalogue">
                                                <LinkButton
                                                      link="/dashboard/catalogues"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            required={true}
                                                            value={title}
                                                            classes={8}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />

                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                      <FormImage
                                                            imageType={true}
                                                            title="PDF File"
                                                            required={true}
                                                            setImage={setFile}
                                                            setImageUrl={
                                                                  setFileUrl
                                                            }
                                                            imageUrl={fileUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default CatalogueEdit;
