import React from 'react';

const CareerIcon = () => {
      return (
            <span className="material-symbols-outlined">
                  social_leaderboard
            </span>
      );
};

export default CareerIcon;
