import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';

const CustomerEnquiryShow = () => {
      const { enquiryId } = useParams();
      const [enquiry, setEnquiry] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/customer_enquiries/${enquiryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setEnquiry(response.data.enquiry);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Enquiry Details">
                                          <LinkButton
                                                link="/dashboard/customer_enquiries"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg="8">
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Full Name"
                                                                  value={
                                                                        enquiry.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email Address"
                                                                  value={
                                                                        enquiry.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Phone"
                                                                  value={
                                                                        enquiry.phone
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        enquiry.date
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Message"
                                                                  value={
                                                                        enquiry.message
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default CustomerEnquiryShow;
