import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'metismenu';
import { NavLink } from 'react-router-dom';
import { MetisMenu } from './styles';
import axios from 'axios';
import toast from 'react-hot-toast';
import DashboardManage from '../menus/DashboardManage';
import UserManage from '../menus/UserManage';
import ContentManage from '../menus/ContentManage';
import WebsiteManage from '../menus/WebsiteManage';
import ProductManage from '../menus/ProductManage';
import StockManage from '../menus/StockManage';
import ExtraContentManage from '../menus/ExraContentManage';

const NavMenu = () => {
      const [userPermissions, setUserPermissions] = useState([]);
      const token = JSON.parse(localStorage.getItem('token'));
      const [loading, setLoading] = useState(false);

      const loadPermission = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/auth/profile`, {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  })
                  .then((response) => {
                        setUserPermissions(response.data.user.permissions);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadPermission();
      }, []);

      useEffect(() => {
            $('#menu').metisMenu();
      });

      return (
            <>
                  {!loading ? (
                        <MetisMenu className="metismenu" id="menu">
                              <DashboardManage
                                    userPermissions={userPermissions}
                              />

                              <UserManage userPermissions={userPermissions} />

                              <ProductManage
                                    userPermissions={userPermissions}
                              />

                              <WebsiteManage
                                    userPermissions={userPermissions}
                              />
                              <ContentManage
                                    userPermissions={userPermissions}
                              />
                              <ExtraContentManage
                                    userPermissions={userPermissions}
                              />
                              <StockManage userPermissions={userPermissions} />
                        </MetisMenu>
                  ) : (
                        <>Loading</>
                  )}
            </>
      );
};

export default NavMenu;
