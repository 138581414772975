import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Dashboard from '../backend/dashboard';

// Category
import {
      CategoryList,
      CategoryCreate,
      CategoryEdit,
      CategoryShow
} from '../backend/category';
import {
      ProductCreate,
      ProductEdit,
      ProductList,
      ProductOrder,
      ProductShow
} from '../backend/products';
import {
      BannerList,
      BannerCreate,
      BannerEdit,
      BannerShow
} from '../backend/banner';
import {
      TestimonialList,
      TestimonialCreate,
      TestimonialShow,
      TestimonialEdit
} from '../backend/testimonials';
import CatalogueList from '../backend/catalogue/CatalogueList';
import CatalogueCreate from '../backend/catalogue/CatalogueCreate';
import CatalogueShow from '../backend/catalogue/CatalogueShow';
import CatalogueEdit from '../backend/catalogue/CatalogueEdit';
import { PageList, PageCreate, PageEdit, PageShow } from '../backend/pages';
import { RoleCreate, RoleEdit, RoleList } from '../backend/roles';
import {
      BlogCategoryList,
      BlogCategoryCreate,
      BlogCategoryEdit,
      BlogCategoryShow
} from '../backend/blogCategory';
import { BlogCreate, BlogEdit, BlogShow, BlogTable } from '../backend/blog';
import {
      FaqTypeCreate,
      FaqTypeEdit,
      FaqTypeList,
      FaqTypeShow
} from '../backend/faqType';
import { FaqCreate, FaqEdit, FaqList, FaqShow } from '../backend/faqs';
import {
      FaqRequestEdit,
      FaqRequestList,
      FaqRequestShow
} from '../backend/faqRequest';
import {
      PluginCreate,
      PluginEdit,
      PluginList,
      PluginShow
} from '../backend/plugins';
import { JobCreate, JobEdit, JobList, JobShow } from '../backend/jobs';
import { CandidateList, CandidateShow } from '../backend/candidates';
import {
      ComplainTypeCreate,
      ComplainTypeEdit,
      ComplainTypeList,
      ComplainTypeShow
} from '../backend/complainTypes';
import { ComplainList, ComplainShow } from '../backend/complains';
import {
      SubscriberCreate,
      SubscriberEdit,
      SubscriberList
} from '../backend/subscribers';
import {
      ContactRequestList,
      ContactRequestShow
} from '../backend/contactRequest';
import { UserCreate, UserEdit, UserList, UserShow } from '../backend/users';
import PageNotFound from '../components/PageNotFound';
import {
      BrandCreate,
      BrandEdit,
      BrandList,
      BrandShow
} from '../backend/brands';
import GalleryList from '../backend/galleries/GalleryList';
import GalleryEdit from '../backend/galleries/GalleryEdit';
import GalleryCreate from '../backend/galleries/GalleryCreate';
import GalleryShow from '../backend/galleries/GalleryShow';
import AchievementList from '../backend/achievements/AchievementList';
import {
      AchievementCreate,
      AchievementEdit,
      AchievementShow
} from '../backend/achievements';
import {
      DownloadCategoryCreate,
      DownloadCategoryEdit,
      DownloadCategoryList,
      DownloadCategoryShow
} from '../backend/download_categories';
import DownloadFileList from '../backend/download_files/DownloadFileList';
import DownloadFileCreate from '../backend/download_files/DownloadFileCreate';
import DownloadFileEdit from '../backend/download_files/DownloadFileEdit';
import DownloadFileShow from '../backend/download_files/DownloadFileShow';
import {
      WarrantyPolicyCreate,
      WarrantyPolicyEdit,
      WarrantyPolicyList,
      WarrantyPolicyShow
} from '../backend/warranty_policies';
import {
      WarrantyProductCreate,
      WarrantyProductEdit,
      WarrantyProductList,
      WarrantyProductShow
} from '../backend/warranty_products';
import {
      InspirationCategoryCreate,
      InspirationCategoryEdit,
      InspirationCategoryList,
      InspirationCategoryShow
} from '../backend/inspiration_categories';
import {
      InspirationFileCreate,
      InspirationFileEdit,
      InspirationFileList,
      InspirationFileShow
} from '../backend/inspiration_files';
import TeamList from '../backend/teams/TeamList';
import TeamCreate from '../backend/teams/TeamCreate';
import TeamEdit from '../backend/teams/TeamEdit';
import TeamShow from '../backend/teams/TeamShow';
import CustomerEnquiryList from '../backend/customer_enquiries/CustomerEnquiryList';
import CustomerEnquiryShow from '../backend/customer_enquiries/CustomerEnquiryShow';
import {
      BranchCreate,
      BranchEdit,
      BranchList,
      BranchShow
} from '../backend/branches';
import {
      ProductEnquiryList,
      ProductEnquiryShow
} from '../backend/product_enquiries';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Brand */}
                        <Route
                              path="/dashboard/brands"
                              element={<BrandList />}
                        />
                        <Route
                              path="/dashboard/brands/create"
                              element={<BrandCreate />}
                        />
                        <Route
                              path="/dashboard/brands/:brandId/edit"
                              element={<BrandEdit />}
                        />
                        <Route
                              path="/dashboard/brands/:brandId/show"
                              element={<BrandShow />}
                        />

                        {/* Category */}
                        <Route
                              path="/dashboard/categories"
                              element={<CategoryList />}
                        />
                        <Route
                              path="/dashboard/categories/create"
                              element={<CategoryCreate />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/edit"
                              element={<CategoryEdit />}
                        />
                        <Route
                              path="/dashboard/categories/:categoryId/show"
                              element={<CategoryShow />}
                        />

                        {/* Product */}
                        <Route
                              path="/dashboard/products"
                              element={<ProductList />}
                        />
                        <Route
                              path="/dashboard/products/create"
                              element={<ProductCreate />}
                        />
                        <Route
                              path="/dashboard/products/:productId/edit"
                              element={<ProductEdit />}
                        />
                        <Route
                              path="/dashboard/products/:productId/show"
                              element={<ProductShow />}
                        />
                        <Route
                              path="/dashboard/products/order"
                              element={<ProductOrder />}
                        />

                        {/* Product Enquiry */}
                        <Route
                              path="/dashboard/product_enquiries"
                              element={<ProductEnquiryList />}
                        />
                        <Route
                              path="/dashboard/product_enquiries/:enquiryId/show"
                              element={<ProductEnquiryShow />}
                        />

                        {/* Faq */}
                        <Route path="/dashboard/faqs" element={<FaqList />} />
                        <Route
                              path="/dashboard/faqs/create"
                              element={<FaqCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/edit"
                              element={<FaqEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/show"
                              element={<FaqShow />}
                        />

                        {/* Faq Type */}
                        <Route
                              path="/dashboard/faqs/types"
                              element={<FaqTypeList />}
                        />
                        <Route
                              path="/dashboard/faqs/types/create"
                              element={<FaqTypeCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/edit"
                              element={<FaqTypeEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/show"
                              element={<FaqTypeShow />}
                        />

                        {/* Faq Request */}
                        <Route
                              path="/dashboard/faqs/requests"
                              element={<FaqRequestList />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/show"
                              element={<FaqRequestShow />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/edit"
                              element={<FaqRequestEdit />}
                        />

                        {/* Testimonial */}
                        <Route
                              path="/dashboard/testimonials"
                              element={<TestimonialList />}
                        />
                        <Route
                              path="/dashboard/testimonials/create"
                              element={<TestimonialCreate />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/edit"
                              element={<TestimonialEdit />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/show"
                              element={<TestimonialShow />}
                        />

                        {/* Plugin */}
                        <Route
                              path="/dashboard/plugins"
                              element={<PluginList />}
                        />
                        <Route
                              path="/dashboard/plugins/create"
                              element={<PluginCreate />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/edit"
                              element={<PluginEdit />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/show"
                              element={<PluginShow />}
                        />

                        {/* Job */}
                        <Route path="/dashboard/jobs" element={<JobList />} />
                        <Route
                              path="/dashboard/jobs/create"
                              element={<JobCreate />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/edit"
                              element={<JobEdit />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/show"
                              element={<JobShow />}
                        />

                        {/* Candidate */}
                        <Route
                              path="/dashboard/candidates"
                              element={<CandidateList />}
                        />
                        <Route
                              path="/dashboard/candidates/:candidateId/show"
                              element={<CandidateShow />}
                        />

                        {/* Complain */}
                        <Route
                              path="/dashboard/complains"
                              element={<ComplainList />}
                        />
                        <Route
                              path="/dashboard/complains/:complainId/show"
                              element={<ComplainShow />}
                        />

                        {/* Complain Type */}
                        <Route
                              path="/dashboard/complains/types"
                              element={<ComplainTypeList />}
                        />
                        <Route
                              path="/dashboard/complains/types/create"
                              element={<ComplainTypeCreate />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/edit"
                              element={<ComplainTypeEdit />}
                        />
                        <Route
                              path="/dashboard/complains/types/:complainTypeId/show"
                              element={<ComplainTypeShow />}
                        />

                        {/* Subscriber */}
                        <Route
                              path="/dashboard/subscribers"
                              element={<SubscriberList />}
                        />
                        <Route
                              path="/dashboard/subscribers/create"
                              element={<SubscriberCreate />}
                        />
                        <Route
                              path="/dashboard/subscribers/:subscriberId/edit"
                              element={<SubscriberEdit />}
                        />

                        {/* Contact Request */}
                        <Route
                              path="/dashboard/contact/requests"
                              element={<ContactRequestList />}
                        />
                        <Route
                              path="/dashboard/contact/requests/:contactId/show"
                              element={<ContactRequestShow />}
                        />

                        {/* Catalogue */}
                        <Route
                              path="/dashboard/catalogues"
                              element={<CatalogueList />}
                        />
                        <Route
                              path="/dashboard/catalogues/create"
                              element={<CatalogueCreate />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/edit"
                              element={<CatalogueEdit />}
                        />
                        <Route
                              path="/dashboard/catalogues/:catalogueId/show"
                              element={<CatalogueShow />}
                        />

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Blog */}
                        <Route
                              path="/dashboard/blogs"
                              element={<BlogTable />}
                        />
                        <Route
                              path="/dashboard/blogs/create"
                              element={<BlogCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/edit"
                              element={<BlogEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/show"
                              element={<BlogShow />}
                        />

                        {/* Blog Category */}
                        <Route
                              path="/dashboard/blogs/categories/list"
                              element={<BlogCategoryList />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/create"
                              element={<BlogCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/edit"
                              element={<BlogCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/show"
                              element={<BlogCategoryShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {/* Roles */}
                        <Route path="/dashboard/roles" element={<RoleList />} />
                        <Route
                              path="/dashboard/roles/create"
                              element={<RoleCreate />}
                        />
                        <Route
                              path="/dashboard/roles/:roleId/edit"
                              element={<RoleEdit />}
                        />

                        {/* User */}
                        <Route path="/dashboard/users" element={<UserList />} />
                        <Route
                              path="/dashboard/users/create"
                              element={<UserCreate />}
                        />
                        <Route
                              path="/dashboard/users/:userId/edit"
                              element={<UserEdit />}
                        />
                        <Route
                              path="/dashboard/users/:userId/show"
                              element={<UserShow />}
                        />

                        {/* Customer Enquiry */}
                        <Route
                              path="/dashboard/customer_enquiries"
                              element={<CustomerEnquiryList />}
                        />
                        <Route
                              path="/dashboard/customer_enquiries/:enquiryId/show"
                              element={<CustomerEnquiryShow />}
                        />

                        {/* Branch */}
                        <Route
                              path="/dashboard/branches"
                              element={<BranchList />}
                        />
                        <Route
                              path="/dashboard/branches/create"
                              element={<BranchCreate />}
                        />
                        <Route
                              path="/dashboard/branches/:branchId/edit"
                              element={<BranchEdit />}
                        />
                        <Route
                              path="/dashboard/branches/:branchId/show"
                              element={<BranchShow />}
                        />

                        {/* Gallery */}
                        <Route
                              path="/dashboard/galleries"
                              element={<GalleryList />}
                        />
                        <Route
                              path="/dashboard/galleries/create"
                              element={<GalleryCreate />}
                        />
                        <Route
                              path="/dashboard/galleries/:galleryId/edit"
                              element={<GalleryEdit />}
                        />
                        <Route
                              path="/dashboard/galleries/:galleryId/show"
                              element={<GalleryShow />}
                        />

                        {/* Achievement */}
                        <Route
                              path="/dashboard/achievements"
                              element={<AchievementList />}
                        />
                        <Route
                              path="/dashboard/achievements/create"
                              element={<AchievementCreate />}
                        />
                        <Route
                              path="/dashboard/achievements/:achievementId/edit"
                              element={<AchievementEdit />}
                        />
                        <Route
                              path="/dashboard/achievements/:achievementId/show"
                              element={<AchievementShow />}
                        />

                        {/* Download Categories */}
                        <Route
                              path="/dashboard/download_categories"
                              element={<DownloadCategoryList />}
                        />
                        <Route
                              path="/dashboard/download_categories/create"
                              element={<DownloadCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/download_categories/:categoryId/edit"
                              element={<DownloadCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/download_categories/:categoryId/show"
                              element={<DownloadCategoryShow />}
                        />

                        {/* Download File */}
                        <Route
                              path="/dashboard/download_files"
                              element={<DownloadFileList />}
                        />
                        <Route
                              path="/dashboard/download_files/create"
                              element={<DownloadFileCreate />}
                        />
                        <Route
                              path="/dashboard/download_files/:fileId/edit"
                              element={<DownloadFileEdit />}
                        />
                        <Route
                              path="/dashboard/download_files/:fileId/show"
                              element={<DownloadFileShow />}
                        />

                        {/* Warranty Policy */}
                        <Route
                              path="/dashboard/warranty_policies"
                              element={<WarrantyPolicyList />}
                        />
                        <Route
                              path="/dashboard/warranty_policies/create"
                              element={<WarrantyPolicyCreate />}
                        />
                        <Route
                              path="/dashboard/warranty_policies/:policyId/edit"
                              element={<WarrantyPolicyEdit />}
                        />
                        <Route
                              path="/dashboard/warranty_policies/:policyId/show"
                              element={<WarrantyPolicyShow />}
                        />

                        {/* Warranty Product */}
                        <Route
                              path="/dashboard/warranty_products"
                              element={<WarrantyProductList />}
                        />
                        <Route
                              path="/dashboard/warranty_products/create"
                              element={<WarrantyProductCreate />}
                        />
                        <Route
                              path="/dashboard/warranty_products/:productId/edit"
                              element={<WarrantyProductEdit />}
                        />
                        <Route
                              path="/dashboard/warranty_products/:productId/show"
                              element={<WarrantyProductShow />}
                        />

                        {/* Inspiration Category */}
                        <Route
                              path="/dashboard/inspiration_categories"
                              element={<InspirationCategoryList />}
                        />
                        <Route
                              path="/dashboard/inspiration_categories/create"
                              element={<InspirationCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/inspiration_categories/:categoryId/edit"
                              element={<InspirationCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/inspiration_categories/:categoryId/show"
                              element={<InspirationCategoryShow />}
                        />

                        {/* Inspiration File */}
                        <Route
                              path="/dashboard/inspiration_files"
                              element={<InspirationFileList />}
                        />
                        <Route
                              path="/dashboard/inspiration_files/create"
                              element={<InspirationFileCreate />}
                        />
                        <Route
                              path="/dashboard/inspiration_files/:imageId/edit"
                              element={<InspirationFileEdit />}
                        />
                        <Route
                              path="/dashboard/inspiration_files/:imageId/show"
                              element={<InspirationFileShow />}
                        />

                        {/* Team  */}
                        <Route path="/dashboard/teams" element={<TeamList />} />
                        <Route
                              path="/dashboard/teams/create"
                              element={<TeamCreate />}
                        />
                        <Route
                              path="/dashboard/teams/:teamId/edit"
                              element={<TeamEdit />}
                        />
                        <Route
                              path="/dashboard/teams/:teamId/show"
                              element={<TeamShow />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
