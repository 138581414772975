import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { TagsInput } from 'react-tag-input-component';

const FormTagEditor = ({
      classes,
      values,
      handleChange,
      title,
      placeholder,
      required
}) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <Form.Label>
                              {title}{' '}
                              {required && <span className="text-danger">*</span>}
                        </Form.Label>
                        <TagsInput
                              value={values}
                              onChange={handleChange}
                              name={title}
                              placeHolder={placeholder}
                        />
                  </Col>
            </>
      );
};

export default FormTagEditor;
