import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import toast from 'react-hot-toast';

const CatalogueShow = () => {
      const { catalogueId } = useParams();
      const [catalogue, setCatalogue] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/catalogues/${catalogueId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCatalogue(response.data.catalogue);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="row">
                        <div className="col-md-12">
                              <div className="card">
                                    <div className="card-header">
                                          <div className="card-title">
                                                Catalogue Detail
                                          </div>
                                          <Link
                                                to="/dashboard/catalogues"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </div>
                                    <div className="card-body">
                                          <div className="row">
                                                {catalogue?.image && (
                                                      <div className="col-md-4">
                                                            <div className="card">
                                                                  <div className="card-body text-center">
                                                                        <img
                                                                              src={`${catalogue.image}`}
                                                                              className="w-100"
                                                                        />
                                                                  </div>
                                                            </div>
                                                      </div>
                                                )}
                                                <div className="col-md-8">
                                                      <ListGroup>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Name :{' '}
                                                                  </strong>
                                                                  {
                                                                        catalogue.title
                                                                  }
                                                            </ListGroup.Item>
                                                            {catalogue?.file && (
                                                                  <ListGroup.Item className="align-items-center d-flex">
                                                                        <strong>
                                                                              PDF
                                                                              :
                                                                        </strong>
                                                                        <a
                                                                              download
                                                                              className="btn btn-warning mt-3"
                                                                              href={`${catalogue?.file}`}
                                                                        >
                                                                              Download
                                                                        </a>
                                                                  </ListGroup.Item>
                                                            )}
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Status :
                                                                  </strong>
                                                                  {catalogue.status ? (
                                                                        <>
                                                                              <span className="text-success">
                                                                                    Active
                                                                              </span>
                                                                        </>
                                                                  ) : (
                                                                        <>
                                                                              <span className="text-warning">
                                                                                    Disable
                                                                              </span>
                                                                        </>
                                                                  )}
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Date :{' '}
                                                                  </strong>
                                                                  {
                                                                        catalogue.date
                                                                  }
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Created
                                                                        By :{' '}
                                                                  </strong>
                                                                  {
                                                                        catalogue.user_name
                                                                  }
                                                            </ListGroup.Item>
                                                      </ListGroup>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default CatalogueShow;
