import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { toast } from 'react-hot-toast';
import Progress from 'react-progress-2';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import LinkButton from '../../components/common/LinkButton';
import EditButton from '../../components/common/EditButton';
import ViewButton from '../../components/common/ViewButton';
import DeleteButton from '../../components/common/DeleteButton';
import ProductImage from '../../components/common/ProductImage';

const DownloadCategoryList = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);
      const [totalItems, setTotalItems] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const loadData = async () => {
            setLoading(true);
            axios.get(
                  `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/download_categories?limit=${limit}&search=${search}`,
                  {
                        headers: {
                              Authorization: `Bearer ${token}`
                        }
                  }
            )
                  .then((response) => {
                        setCategories(response.data.categories);
                        setTotalItems(response.data.totalItems);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/download_categories?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                              setTotalItems(response.data.totalItems);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      const handleStatus = async (category) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/download_categories/${category.slug}/status`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (category) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/download_categories/${category.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories((categories) =>
                                    categories.filter(
                                          (item) => item.id !== category.id
                                    )
                              );
                              loadData();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <Row>
                        <Col className="col-md-12">
                              <Card className="card">
                                    <CardHeader title="Category List">
                                          <LinkButton
                                                link="/dashboard/download_categories/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          perPages={perPages}
                                          totalItems={totalItems}
                                          currentPage={currentPage}
                                          getData={getData}
                                          setSearch={setSearch}
                                    >
                                          <thead>
                                                <tr>
                                                      <th
                                                            width="80px"
                                                            className="text-center"
                                                      >
                                                            #
                                                      </th>
                                                      <th>Title</th>
                                                      <th>Date</th>
                                                      <th width="80px">
                                                            Status
                                                      </th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      categories?.length > 0 ? (
                                                            categories?.map(
                                                                  (
                                                                        category,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                ++index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      category.title
                                                                                                }
                                                                                                image={
                                                                                                      category.image
                                                                                                }
                                                                                                category={`By: ${category.user_name}`}
                                                                                          />
                                                                                    </td>
                                                                                    <td>
                                                                                          {
                                                                                                category.date
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <Form.Check
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                defaultChecked={
                                                                                                      category.status ===
                                                                                                      1
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                                onClick={() =>
                                                                                                      handleStatus(
                                                                                                            category
                                                                                                      )
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <EditButton
                                                                                                link={`/dashboard/download_categories/${category.slug}/edit`}
                                                                                          />
                                                                                          <ViewButton
                                                                                                link={`/dashboard/download_categories/${category.slug}/show`}
                                                                                          />
                                                                                          <DeleteButton
                                                                                                item={
                                                                                                      category
                                                                                                }
                                                                                                loading={
                                                                                                      loading
                                                                                                }
                                                                                                setLoading={
                                                                                                      setLoading
                                                                                                }
                                                                                                handleDelete={
                                                                                                      handleDelete
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    5
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              category
                                                                              Found
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              5
                                                                        }
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default DownloadCategoryList;
