import React from 'react';

const BlogIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">rss_feed</span>
            </>
      );
};

export default BlogIcon;
