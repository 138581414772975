import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import toast from 'react-hot-toast';

const BranchShow = () => {
      const { branchId } = useParams();
      const [branch, setBranch] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/branches/${branchId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBranch(response.data.branch);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <div className="row">
                        <div className="col-md-12">
                              <div className="card">
                                    <div className="card-header">
                                          <div className="card-title">
                                                Branch Detail
                                          </div>
                                          <Link
                                                to="/dashboard/branches"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </div>
                                    <div className="card-body">
                                          <div className="row">
                                                <div className="col-md-8">
                                                      <ListGroup>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Branch
                                                                        Name :{' '}
                                                                  </strong>
                                                                  {branch.title}
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Email
                                                                        Address
                                                                        :{' '}
                                                                  </strong>
                                                                  {branch.email}
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Phone
                                                                        No. :{' '}
                                                                  </strong>
                                                                  {
                                                                        branch.phone_number
                                                                  }
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Address
                                                                        :{' '}
                                                                  </strong>
                                                                  {
                                                                        branch.address
                                                                  }
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Status :
                                                                  </strong>
                                                                  {branch.status ? (
                                                                        <>
                                                                              <span className="text-success">
                                                                                    Active
                                                                              </span>
                                                                        </>
                                                                  ) : (
                                                                        <>
                                                                              <span className="text-warning">
                                                                                    Disable
                                                                              </span>
                                                                        </>
                                                                  )}
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Date :{' '}
                                                                  </strong>
                                                                  {branch.date}
                                                            </ListGroup.Item>
                                                            <ListGroup.Item>
                                                                  <strong>
                                                                        Created
                                                                        By :{' '}
                                                                  </strong>
                                                                  {
                                                                        branch.user_name
                                                                  }
                                                            </ListGroup.Item>
                                                      </ListGroup>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default BranchShow;
