import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../components/icons';
import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import ProductImage from '../../components/common/ProductImage';
import CardBody from '../../components/common/CardBody';

const RoleList = () => {
      const [roles, setRoles] = useState([]);
      const [loading, setLoading] = useState(false);
      const [totalRoles, setTotalRoles] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles?limit=${limit}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setRoles(response.data.roles);
                        setTotalRoles(response.data.totalRoles);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [limit]);

      async function getRoleData(pageNumber = 1) {
            window.screenTop(0, 0);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles?page=${pageNumber}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            }).then((response) => {
                  setRoles(response.data.roles);
                  setTotalRoles(response.data.totalRoles);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);
                  Progress.hide();
            });
      }

      const handleDelete = async (role) => {
            setLoading(true);
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/roles/${role.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              loadData();
                              toast.success(response.data.message);
                        }
                        setLoading(false);
                  });
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Role List">
                                          <LinkButton
                                                link="/dashboard/roles/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <CardBody
                                          setLimit={setLimit}
                                          perPages={perPages}
                                          totalItems={totalRoles}
                                          currentPage={currentPage}
                                          getData={getRoleData}
                                    >
                                          <thead>
                                                <tr>
                                                      <th
                                                            className="text-center"
                                                            width="60px"
                                                      >
                                                            #
                                                      </th>
                                                      <th> Title</th>
                                                      <th width="120px">
                                                            Action
                                                      </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      roles.length > 0 ? (
                                                            roles.map(
                                                                  (
                                                                        role,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-center">
                                                                                          {
                                                                                                role.index
                                                                                          }
                                                                                    </td>
                                                                                    <td>
                                                                                          <ProductImage
                                                                                                title={
                                                                                                      role.title
                                                                                                }
                                                                                          />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                          <Link
                                                                                                to={`/dashboard/roles/${role.id}/edit`}
                                                                                                className="text-secondary"
                                                                                          >
                                                                                                <EditIcon />
                                                                                          </Link>
                                                                                          <Link
                                                                                                onClick={() =>
                                                                                                      handleDelete(
                                                                                                            role
                                                                                                      )
                                                                                                }
                                                                                                className="text-danger mx-2"
                                                                                          >
                                                                                                <DeleteIcon />
                                                                                          </Link>
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="5"
                                                                              className="text-center"
                                                                        >
                                                                              No
                                                                              Role
                                                                              Found.
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan="5"
                                                                        className="text-center"
                                                                  >
                                                                        Loading...
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default RoleList;
