import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import {
      BlogIcon,
      CatalogueIcon,
      DownloadIcon,
      GalleryIcon,
      TeamIcon,
      WarrantyIcon
} from '../../dashboard';
import MenuLink from '../../metis/MenuLink';

const ExtraContentManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'achievement-list',
                              'achievement-create',
                              'achievement-edit',
                              'achievement-show',
                              'download-category-list',
                              'download-category-edit',
                              'download-category-create',
                              'download-category-delete',
                              'download-file-list',
                              'download-file-create',
                              'download-file-delete',
                              'download-file-edit',
                              'inspiration-category-index',
                              'inspiration-category-edit',
                              'inspiration-category-create',
                              'inspiration-category-delete',
                              'inspiration-file-index',
                              'inspiration-file-create',
                              'inspiration-file-delete',
                              'inspiration-file-edit',
                              'warranty-policy-list',
                              'warranty-policy-create',
                              'warranty-policy-edit',
                              'warranty-policy-delete',
                              'warranty-product-list',
                              'warranty-product-create',
                              'warranty-product-edit',
                              'warranty-product-delete',
                              'team-list',
                              'team-create',
                              'team-edit',
                              'team-delete',
                              'gallery-list',
                              'gallery-create',
                              'gallery-edit',
                              'gallery-delete'
                        ]}
                  >
                        <MenuSeparator>Extra Content Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'gallery-create',
                                    'gallery-list',
                                    'gallery-edit',
                                    'gallery-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Gallery Manage"
                                    link="/dashboard/galleries"
                                    icon={<GalleryIcon />}
                                    links={[
                                          '/dashboard/galleries',
                                          '/dashboard/galleries/create',
                                          '/dashboard/galleries/:galleryId/edit',
                                          '/dashboard/galleries/:galleryId/show'
                                    ]}
                              />
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'team-list',
                                    'team-create',
                                    'team-edit',
                                    'team-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Team Manage"
                                    link="/dashboard/teams"
                                    icon={<TeamIcon />}
                                    links={[
                                          '/dashboard/teams',
                                          '/dashboard/teams/create',
                                          '/dashboard/teams/:teamId/edit',
                                          '/dashboard/teams/:teamId/show'
                                    ]}
                              />
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'achievement-list',
                                    'achievement-create',
                                    'achievement-edit',
                                    'achievement-show'
                              ]}
                        >
                              <MenuItem
                                    title="Achievement Manage"
                                    link="/dashboard/achievements"
                                    icon={<CatalogueIcon />}
                                    links={[
                                          '/dashboard/achievements',
                                          '/dashboard/achievements/create',
                                          '/dashboard/achievements/:achievementId/edit',
                                          '/dashboard/achievements/:achievementId/show'
                                    ]}
                              />
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'download-category-list',
                                    'download-category-edit',
                                    'download-category-create',
                                    'download-category-delete',
                                    'download-file-list',
                                    'download-file-create',
                                    'download-file-delete',
                                    'download-file-edit'
                              ]}
                        >
                              <MenuItem
                                    title="Download Manage"
                                    link="javascript:void(0)"
                                    icon={<DownloadIcon />}
                                    links={[
                                          '/dashboard/download_categories',
                                          '/dashboard/download_categories/create',
                                          '/dashboard/download_categories/:categoryId/edit',
                                          '/dashboard/download_categories/:categoryId/show',
                                          '/dashboard/download_files',
                                          '/dashboard/download_files/create',
                                          '/dashboard/download_files/:fileId/edit',
                                          '/dashboard/download_files/:fileId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'download-file-list',
                                                'download-file-create',
                                                'download-file-delete',
                                                'download-file-edit'
                                          ]}
                                    >
                                          <MenuLink
                                                title="File List"
                                                link="/dashboard/download_files"
                                                links={[
                                                      '/dashboard/download_files',
                                                      '/dashboard/download_files/create',
                                                      '/dashboard/download_files/:fileId/edit',
                                                      '/dashboard/download_files/:fileId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'download-category-list',
                                                'download-category-edit',
                                                'download-category-create',
                                                'download-category-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category List"
                                                link="/dashboard/download_categories"
                                                links={[
                                                      '/dashboard/download_categories',
                                                      '/dashboard/download_categories/create',
                                                      '/dashboard/download_categories/:categoryId/edit',
                                                      '/dashboard/download_categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'inspiration-category-index',
                                    'inspiration-category-edit',
                                    'inspiration-category-create',
                                    'inspiration-category-delete',
                                    'inspiration-file-index',
                                    'inspiration-file-create',
                                    'inspiration-file-delete',
                                    'inspiration-file-edit'
                              ]}
                        >
                              <MenuItem
                                    title="Inspiration Manage"
                                    icon={<BlogIcon />}
                                    link="javascript:void(0)"
                                    links={[
                                          '/dashboard/inspiration_categories',
                                          '/dashboard/inspiration_categories/create',
                                          '/dashboard/inspiration_categories/:categoryId/edit',
                                          '/dashboard/inspiration_categories/:categoryId/show',
                                          '/dashboard/inspiration_files',
                                          '/dashboard/inspiration_files/create',
                                          '/dashboard/inspiration_files/:imageId/edit',
                                          '/dashboard/inspiration_files/:imageId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'inspiration-file-index',
                                                'inspiration-file-create',
                                                'inspiration-file-delete',
                                                'inspiration-file-edit'
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/inspiration_files"
                                                title="All File"
                                                links={[
                                                      '/dashboard/inspiration_files',
                                                      '/dashboard/inspiration_files/create',
                                                      '/dashboard/inspiration_files/:typeId/edit',
                                                      '/dashboard/inspiration_files/:typeId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'inspiration-category-index',
                                                'inspiration-category-edit',
                                                'inspiration-category-create',
                                                'inspiration-category-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/inspiration_categories"
                                                title="Category List"
                                                links={[
                                                      '/dashboard/inspiration_categories',
                                                      '/dashboard/inspiration_categories/create',
                                                      '/dashboard/inspiration_categories/:categoryId/edit',
                                                      '/dashboard/inspiration_categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'warranty-policy-list',
                                    'warranty-policy-create',
                                    'warranty-policy-edit',
                                    'warranty-policy-delete',
                                    'warranty-product-list',
                                    'warranty-product-create',
                                    'warranty-product-edit',
                                    'warranty-product-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Warranty Manage"
                                    icon={<WarrantyIcon />}
                                    link="javascript:void(0)"
                                    links={[
                                          '/dashboard/warranty_policies',
                                          '/dashboard/warranty_policies/create',
                                          '/dashboard/warranty_policies/:policyId/edit',
                                          '/dashboard/warranty_policies/:policyId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'warranty-policy-list',
                                                'warranty-policy-create',
                                                'warranty-policy-edit',
                                                'warranty-policy-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Policy List"
                                                link="/dashboard/warranty_policies"
                                                links={[
                                                      '/dashboard/warranty_policies',
                                                      '/dashboard/warranty_policies/create',
                                                      '/dashboard/warranty_policies/:policyId/edit',
                                                      '/dashboard/warranty_policies/:policyId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'warranty-product-list',
                                                'warranty-product-create',
                                                'warranty-product-edit',
                                                'warranty-product-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product List"
                                                link="/dashboard/warranty_products"
                                                links={[
                                                      '/dashboard/warranty_products',
                                                      '/dashboard/warranty_products/create',
                                                      '/dashboard/warranty_products/:productId/edit',
                                                      '/dashboard/warranty_products/:productId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ExtraContentManage;
