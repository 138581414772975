import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import MenuLink from '../../metis/MenuLink';
import { ProductIcon } from '../../dashboard';

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'product-list',
                              'product-create',
                              'product-edit',
                              'product-show',
                              'category-list',
                              'category-create',
                              'category-edit',
                              'category-show',
                              'product-enquiry-list',
                              'product-enquiry-show'
                        ]}
                  >
                        <MenuSeparator>product Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'product-list',
                                    'product-create',
                                    'product-edit',
                                    'product-show',
                                    'category-list',
                                    'category-create',
                                    'category-edit',
                                    'category-show',
                                    'product-enquiry-list',
                                    'product-enquiry-show'
                              ]}
                        >
                              <MenuItem
                                    title="Products"
                                    link="javascript:void(0)"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/products',
                                          '/dashboard/products/create',
                                          '/dashboard/products/:productId/edit',
                                          '/dashboard/products/:productId/show',
                                          '/dashboard/categories',
                                          '/dashboard/categories/create',
                                          '/dashboard/categories/:categoryId/edit',
                                          '/dashboard/categories/:categoryId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'category-list',
                                                'category-create',
                                                'category-edit',
                                                'category-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category"
                                                link="/dashboard/categories"
                                                links={[
                                                      '/dashboard/categories',
                                                      '/dashboard/categories/create',
                                                      '/dashboard/categories/:categoryId/edit',
                                                      '/dashboard/categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'product-list',
                                                'product-create',
                                                'product-edit',
                                                'product-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product List"
                                                link="/dashboard/products"
                                                links={[
                                                      '/dashboard/products',
                                                      '/dashboard/products/create',
                                                      '/dashboard/products/:productId/edit',
                                                      '/dashboard/products/:productId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'product-enquiry-list',
                                    'product-enquiry-show'
                              ]}
                        >
                              <MenuItem
                                    title="Product Enquiry"
                                    link="javascript:void(0)"
                                    icon={
                                          <span class="material-symbols-outlined">
                                                contact_phone
                                          </span>
                                    }
                                    links={[
                                          '/dashboard/product_enquiries',
                                          '/dashboard/product_enquiries/:enquiryId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'product-enquiry-list',
                                                'product-enquiry-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="All Enquiries"
                                                link="/dashboard/product_enquiries"
                                                links={[
                                                      '/dashboard/product_enquiries',
                                                      '/dashboard/product_enquiries/:enquiryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
