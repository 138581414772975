import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import toast from 'react-hot-toast';
import { Card, Col, ListGroupItem, Row, Spinner } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';

const InspirationFileShow = () => {
      const { imageId } = useParams();
      const [loading, setLoading] = useState(false);
      const [image, setImage] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/inspiration_files/${imageId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setImage(response.data.image);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Inspiration File Details">
                                          <LinkButton
                                                link="/dashboard/inspiration_files"
                                                title="Back"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg="8">
                                                            <ListGroup>
                                                                  <ListGroupItem>
                                                                        <img
                                                                              src={
                                                                                    image.file_url
                                                                              }
                                                                              style={{
                                                                                    width: '120px'
                                                                              }}
                                                                        />
                                                                  </ListGroupItem>
                                                                  <ItemShow
                                                                        title="Name"
                                                                        value={
                                                                              image.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Category Name"
                                                                        value={
                                                                              image.category
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              image.status ? (
                                                                                    <>
                                                                                          <span className="text-success">
                                                                                                Active
                                                                                          </span>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <span className="text-warning">
                                                                                                Disable
                                                                                          </span>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="By "
                                                                        value={
                                                                              image.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              image.date
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <>
                                                      <div className="h-25 text-center">
                                                            <Spinner />
                                                      </div>
                                                </>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default InspirationFileShow;
